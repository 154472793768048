//use strict

if (typeof MS2JS === 'undefined') {
    var MS2JS = {};
}

(function () {
    MS2JS.Http = new (function () {
        return {
            get: get,
            post: post,
            getMS2Api: getMS2Api,
        };

        function getMS2Api(url, callback) {
            window['_boot'].then(() => {
                const headers = {
                    'x-bwin-browser-url': window.location.href,
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                };

                if (window.clientConfig.vnProducts.sports.enabled) {
                    headers['x-bwin-sports-api'] = window.clientConfig.vnPage.environment;
                    url = window.clientConfig.vnProducts.sports.apiBaseUrl + url;
                }

                get(url, callback, {
                    headers: headers,
                });
            });
        }

        function get(url, callback, options) {
            asyncRequest(url, 'GET', callback, options);
        }

        function post(url, callback, options) {
            asyncRequest(url, 'POST', callback, options);
        }

        function asyncRequest(url, method, callback, options) {
            options = options || {};

            var defaultHeaders = { 'Content-Type': 'application/json;charset=UTF-8' };
            var headers = Object.assign({}, defaultHeaders, options.headers || {});
            var data = options.data ? JSON.stringify(options.data) : undefined;

            return fetch(url, {
                method: method,
                cache: 'no-cache',
                credentials: 'include',
                headers: headers,
                body: data,
            })
                .then((res) => res.json())
                .then((data) => callback(null, data))
                .catch((err) => {
                    console.error(err);
                    callback(err);
                });
        }

        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
    })();

    MS2JS.GridGroupLoader = new (function () {
        var isLoading = false;
        var groupingData = null;
        var hasError = false;

        init();

        return {
            loading: function () {
                return isLoading;
            },
            error: function () {
                return hasError;
            },
            data: function () {
                return groupingData;
            },
            reset: function () {
                isLoading = false;
                groupingData = null;
                hasError = false;
            },
        };

        function init() {
            isLoading = true;
            MS2JS.Http.get(preloadGroupingUrl, function (error, data) {
                hasError = !!error;
                isLoading = false;
                groupingData = data;
            });
        }
    })();

    MS2JS.ModularHomePageDataLoader = new (function () {
        var isLoading = false;
        var homePageData = null;
        var hasError = false;
        let requestContext = null;

        const mediaQueryMap = {
            'gt-xs': 'screen and (min-width: 600px)',
            'gt-sm': 'screen and (min-width: 960px)',
            'gt-md': 'screen and (min-width: 1280px)',
            'gt-mw': 'screen and (min-width: 1600px)',
            'gt-lg': 'screen and (min-width: 1920px)',
            'gt-wd': 'screen and (min-width: 1920px)',
            'xs-height': '(max-height: 479px)',
            'gt-xs-height': '(min-height: 480px)',
            'xl': 'screen and (min-width: 1920px) and (max-width: 4999.98px)',
            'lt-xl': 'screen and (max-width: 1919.98px)',
            'lg': 'screen and (min-width: 1280px) and (max-width: 1919.98px)',
            'wd': 'screen and (min-width: 1600px) and (max-width: 1919.9px)',
            'lt-wd': 'screen and (max-width: 1599.9px)',
            'lt-lg': 'screen and (max-width: 1279.98px)',
            'mw': 'screen and (min-width: 1280px) and (max-width: 1599.9px)',
            'lt-mw': 'screen and (max-width: 1279.9px)',
            'md': 'screen and (min-width: 960px) and (max-width: 1279.98px)',
            'lt-md': 'screen and (max-width: 959.98px)',
            'sm': 'screen and (min-width: 600px) and (max-width: 959.98px)',
            'lt-sm': 'screen and (max-width: 599.98px)',
            'xs': 'screen and (min-width: 0px) and (max-width: 599.98px)',
        };

        init();

        return {
            loading: function () {
                return isLoading;
            },
            error: function () {
                return hasError;
            },
            data: function () {
                return homePageData;
            },
            requestContext: function () {
                return requestContext;
            },
            reset: function () {
                isLoading = false;
                requestContext = null;
                homePageData = null;
                hasError = false;
            },
        };

        function init() {
            preloadStyles();

            const isSportsLobby = isOnSportsLobby();
            if (!isOnHomepage() && !isSportsLobby) {
                return;
            }

            var screenSize = getScreenSize();

            isLoading = true;
            let url = preloadPagePrefix + `/api/widget?layoutSize=${screenSize}&page=HomeLobby`;
            let contextData = null;
            if (isSportsLobby) {
                const sportsPathIndex = window.location.pathname.indexOf('/sports/');
                const sportsIdPath = window.location.pathname.substring(sportsPathIndex + 8);
                const sportPathSplitted = sportsIdPath.split('-');
                const sportId = sportPathSplitted[sportPathSplitted.length - 1];
                url = preloadPagePrefix + `/api/widget?layoutSize=${screenSize}&page=SportLobby&sportId=${sportId}`;
                contextData = {
                    page: 'SportLobby',
                    layoutSize: screenSize,
                    sportId: parseInt(sportId),
                };
            }

            MS2JS.Http.getMS2Api(url, function (error, data) {
                hasError = !!error;
                isLoading = false;
                homePageData = data;
                requestContext = contextData;
                if (data?.widgets) {
                    preloadLcpImages(data.widgets);
                }
            });
        }

        function getScreenSize() {
            if (window.matchMedia('(min-width:601px) and (max-width: 1279px)').matches) {
                return 'Medium';
            } else if (window.matchMedia('(min-width: 1280px)').matches) {
                return 'Large';
            }

            return 'Small';
        }

        function preloadStyles() {
            window['_boot'].then(() => {
                const { vnLazyStyles } = window.clientConfig;
                if (vnLazyStyles) {
                    vnLazyStyles.stylesheets.forEach((stylesheet) => {
                        const isImportant = stylesheet.lazyLoad === 'Important';
                        if (isImportant || stylesheet.lazyLoad === 'Secondary') {
                            const link = document.createElement('link');
                            link.href = stylesheet.url;
                            if (mediaQueryMap[stylesheet.media]) {
                                link.media = mediaQueryMap[stylesheet.media];
                            }
                            link.rel = 'stylesheet';
                            link.type = 'text/css';
                            document.head.appendChild(link);
                        }
                    });
                }
            });
        }

        function preloadLcpImages(widgets) {
            const staticTeaser = widgets.find((widget) => widget.type === 'StaticTeaser');
            preloadStaticTeaserLcp(staticTeaser);
            const storyContent = widgets.find((widget) => widget.type === 'StoryContent');
            preloadStoryContentLcp(storyContent);
            const teaser = widgets.find((widget) => widget.type === 'RotatingTeaser');
            preloadTeaserLcp(teaser);
            const marquee = widgets.find((widget) => widget.type === 'Marquee');
            preloadMarqueeLcp(marquee);
            // for casinoBanner we need the msImages config
            window['_boot'].then(() => {
                const casinoBanner = widgets.find((widget) => widget.type === 'Banner' && !!widget.payload?.imageUrl);
                preloadCasinoBannerLcp(casinoBanner);
            });
        }

        function preloadStoryContentLcp(widget) {
            if (widget) {
                const stories = widget.payload.stories;
                const story = stories?.length > 0 ? stories[0] : null;
                if (story?.tileImage) {
                    const stretch = stories.length > 1;
                    const imageUrl = new URL(story?.tileImage);
                    const backgroundWidth = stretch ? '540' : '160';
                    const qualityParam = `width${backgroundWidth}`;

                    imageUrl.searchParams.set('p', qualityParam);
                    createImagePreloadLink(imageUrl.toString());
                }
            }
        }

        function preloadMarqueeLcp(widget) {
            if (widget && widget.payload?.tiles?.length > 0) {
                const tile = widget.payload.tiles[0];
                if (tile.imageUrl) {
                    const srcSet = ['280', '560'].map((size, i) => `${tile.imageUrl}?w=${size} ${i + 1}x`);
                    createImagePreloadLink(tile.imageUrl, null, srcSet);
                }
            }
        }

        function preloadTeaserLcp(widget) {
            if (widget) {
                const teasers = widget.payload.teasers;
                if (teasers?.length > 0 && teasers[0].imageUrl) {
                    const imageUrl = teasers[0].imageUrl;
                    const srcSet = ['480', '640', '768'].map((size) => `${imageUrl}?w=${size} ${size}w`).join(', ');
                    const sizes = '(min-width: 960px) 768px, 100vw';
                    createImagePreloadLink(imageUrl, sizes, srcSet);
                }
            }
        }

        function preloadStaticTeaserLcp(widget) {
            if (widget) {
                const teaser = widget.payload;
                if (teaser?.imageUrl) {
                    const imageUrl = `${teaser.imageUrl}?p=width540`;
                    createImagePreloadLink(imageUrl);
                }
            }
        }

        function preloadCasinoBannerLcp(widget) {
            if (widget) {
                const { msImages } = window.clientConfig;
                let imageUrl = widget.payload.imageUrl;
                if (msImages?.imageQualityParams?.homePage?.casinoBanner) {
                    imageUrl = setImageQuality(
                        imageUrl,
                        msImages.imageQualityParams.allowedImageTypes,
                        msImages.imageQualityParams.homePage.casinoBanner,
                    );
                }
                createImagePreloadLink(imageUrl);
            }
        }

        function setImageQuality(imageSource, allowedImageTypes, qualityValue) {
            const allowedExtensions = new RegExp(allowedImageTypes, 'i');

            if (imageSource && imageSource.match(allowedExtensions) && !imageSource.includes('q='))
                return (imageSource += imageSource.includes('?') ? '&' + qualityValue : '?' + qualityValue);

            return imageSource;
        }

        function createImagePreloadLink(src, sizes, srcSet) {
            const preloadLink = document.createElement('link');
            preloadLink.rel = 'preload';
            preloadLink.href = src;
            if (sizes) {
                preloadLink.imageSizes = sizes;
            }
            if (srcSet) {
                preloadLink.imageSrcset = srcSet;
            }
            preloadLink.as = 'image';
            preloadLink.setAttribute('fetchpriority', 'high');
            document.head.appendChild(preloadLink);
        }
    })();

    MS2JS.SiteMapLoader = new (function () {
        var isLoading = false;
        var siteMapData = null;
        var hasError = false;

        init();

        return {
            loading: function () {
                return isLoading;
            },
            error: function () {
                return hasError;
            },
            data: function () {
                return siteMapData;
            },
            reset: function () {
                isLoading = false;
                siteMapData = null;
                hasError = false;
            },
        };

        function init() {
            if (!shouldLoadSitemap) {
                return;
            }

            isLoading = true;
            MS2JS.Http.getMS2Api(`${preloadPagePrefix}/api/sitemap`, function (error, data) {
                hasError = !!error;
                isLoading = false;
                siteMapData = data;
            });
        }
    })();

    MS2JS.TeaserPricesTableLoader = new (function () {
        var isLoading = false;
        var teaserPrices = null;
        var hasError = false;

        init();

        return {
            loading: function () {
                return isLoading;
            },
            error: function () {
                return hasError;
            },
            data: function () {
                return teaserPrices;
            },
            reset: function () {
                isLoading = false;
                teaserPrices = null;
                hasError = false;
            },
        };

        function init() {
            isLoading = true;
            MS2JS.Http.getMS2Api(`${preloadPagePrefix}/api/teaserbet/getLatestTeaserPrices`, function (error, data) {
                hasError = !!error;
                isLoading = false;
                teaserPrices = data;
            });
        }
    })();

    function isOnSportsLobby() {
        const sportsRegex = /sports\/\D+(-\d+){1,2}\/?$/gm;
        return sportsRegex.test(decodeURI(window.location.pathname));
    }

    function isOnHomepage() {
        var homepageUrlRegex = new RegExp(preloadPagePrefix + '$', 'i');
        return homepageUrlRegex.test(window.location.pathname);
    }
})();
